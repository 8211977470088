import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SettingsIcon from "@material-ui/icons/Settings";

import { Link } from "gatsby";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    boxShadow: "0 3px 7px",
  },
});

const BottomNavComponent = ({ location }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const paths = ["/a/", "/a/recurring/", "/a/settings/"];
  useEffect(() => {
    if (location !== null) {
      const index = paths.findIndex(a => a.indexOf(location.pathname) === 0);
      setValue(index);
    }
  }, [location, paths]);
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Transactions"
        icon={<AttachMoneyIcon />}
        component={Link}
        to={paths[0]}
      />
      {/* <BottomNavigationAction
        label="Recurring"
        icon={<UpdateIcon />}
        component={Link}
        to={paths[1]}
      /> */}
      <BottomNavigationAction
        label="Settings"
        icon={<SettingsIcon />}
        component={Link}
        to={paths[2]}
      />
    </BottomNavigation>
  );
};

export default BottomNavComponent;
