import React from "react";

import { Link } from "gatsby";

import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#dddddd",
    },
  },
  color: {},
}));
const ActionButton = ({ color, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(!color && classes.button)}
      component={Link}
      to="/a"
      {...rest}
    >
      {children || "Get Started"}
    </Button>
  );
};

export default ActionButton;
