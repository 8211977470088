import React from "react";
import { Location } from "@reach/router";

import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";

import clsx from "clsx";

import logo from "../assets/icon.png";

import ActionButton from "../old_pages/Landing/ActionButton";

import Container from "./Container";
import BottomNav from "./BottomNav";

import SEO from "./seo";

import "../styles/global.scss";

const useStyles = makeStyles(theme => ({
  layoutRoot: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: ({ topNavPosition }) =>
        topNavPosition === "fixed" ? 56 : "initial",
      paddingBottom: ({ noBottomNav }) => (noBottomNav ? 0 : 56),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: ({ topNavPosition }) =>
        topNavPosition === "fixed" ? 64 : "initial",
      paddingBottom: ({ noBottomNav }) => (noBottomNav ? 0 : 64),
    },
  },
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "baseline",
  },
  titleSmModifier: {
    "@media (max-width: 350px)": {
      flexDirection: "column",
      padding: "0 0 6px",
    },
  },
  betaTag: {
    marginLeft: theme.spacing(0.5),
    backgroundColor: "#e8e8e8",
    color: theme.palette.primary.main,
    padding: "0 4px",
    width: "fit-content",
    borderRadius: "3px",
    fontSize: ".9rem",
  },
  betaTagSmModifier: {
    "@media (max-width: 350px)": {
      margin: "initial",
    },
  },
}));

const Layout = ({
  maxWidth = "sm",
  children,
  noBottomNav = false,
  showAction = false,
}) => {
  const topNavPosition = noBottomNav ? "static" : "fixed";

  const classes = useStyles({ noBottomNav, topNavPosition });
  return (
    <div className={classes.layoutRoot}>
      <SEO />
      <AppBar position={topNavPosition}>
        <Toolbar>
          <img src={logo} style={{ width: 26, paddingRight: 10 }} alt="logo" />
          <div
            className={clsx(
              classes.title,
              showAction && classes.titleSmModifier
            )}
          >
            <Typography variant="h6">Reconcile</Typography>
            <div
              className={clsx(
                classes.betaTag,
                showAction && classes.titleSmModifier
              )}
            >
              beta
            </div>
          </div>

          {showAction && <ActionButton>login</ActionButton>}
        </Toolbar>
      </AppBar>
      <Container
        className={topNavPosition === "fixed" ? classes.containerFixed : ""}
        maxWidth={maxWidth}
      >
        {children}
      </Container>
      {!noBottomNav && (
        <Location>
          {({ location }) => <BottomNav location={location} />}
        </Location>
      )}
    </div>
  );
};

export default Layout;
